import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  Profile,
  ProfileService,
  RouterHistoryService,
  SessionService,
} from '@tenant/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';
import { HeaderConfig } from './factories/header-config';
import { HeaderService } from './header.service';

@Component({
  selector: 'ot-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @HostBinding('class.has-impersonate') public isImpersonate = false;

  @Output() openMobileMenuBar: EventEmitter<boolean> = new EventEmitter(false);

  public title = '';
  public menuOpened = false;
  public showSearchField = false;
  public config: HeaderConfig;
  public profile: Profile;
  public impersonateState: Observable<boolean>;
  public announcementMessage: Observable<string>;
  private subs: Subscription[] = [];

  constructor(
    public session: SessionService,
    private header: HeaderService,
    private _profile: ProfileService,
    private router: Router,
    private routerHistoryService: RouterHistoryService
  ) {
    this.impersonateState = session.impersonateState;
    this.announcementMessage = session.announcementMessage;
    this.impersonateState.subscribe((v) => {
      this.isImpersonate = v;
    });
  }

  public toggleMenu($event) {
    $event.preventDefault();
    this.menuOpened = !this.menuOpened;
  }

  public closeMenu() {
    this.menuOpened = false;
  }

  public exitImpersonate() {
    this.session.logoutFromImpersonate();
  }

  public ngOnInit() {
    this.subs.push(
      this.header.config.subscribe((config) => {
        this.config = config;
      })
    );
    this.subs.push(
      this._profile.state.subscribe((profile) => {
        this.profile = profile;
      })
    );
    this.subs.push(
      this.router.events
        .pipe(
          skipWhile((event) => {
            return !(event instanceof NavigationStart);
          })
        )
        .subscribe((res) => {
          this.closeMenu();
        })
    );

    this.subs.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => {
            let route: ActivatedRoute = this.router.routerState.root;
            let routeTitle = '';
            while (route!.firstChild) {
              route = route.firstChild;
            }
            if (route.snapshot.data['title']) {
              routeTitle = route!.snapshot.data['title'];
            }
            return routeTitle;
          })
        )
        .subscribe((title: string) => {
          if (title) {
            if (title === 'check_deal_title') {
              if (
                this.routerHistoryService
                  .getPreviousUrl()
                  ?.includes('deal-approval') ||
                this.title === 'Deal Approvals'
              ) {
                this.title = 'Deal Approvals';
              } else if (
                this.routerHistoryService
                  .getPreviousUrl()
                  ?.includes('accounting') ||
                this.title === 'Accounting Queue'
              ) {
                this.title = 'Accounting Queue';
              } else {
                this.title = 'My company deals';
              }
            } else {
              this.title = title;
            }
          }
        })
    );

    this.session.getSettings();
  }

  public onOpenMobileMenuBar() {
    this.openMobileMenuBar.emit(true);
  }

  public ngOnDestroy() {
    this.subs.forEach((v: Subscription) => {
      v.unsubscribe();
    });
  }

  public openWidget() {
    (window as any).FreshworksWidget('open');
  }

  public onShowSearch() {
    this.showSearchField = true;
  }

  public onHideSearch(event) {
    event.stopPropagation();
    this.showSearchField = false;
  }
}
